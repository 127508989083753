<template>
  <b-card-code class="table">
    <div class="d-flex justify-content-end align-items-center">
      <div>
        <VueGoodTableSwitchCompactMode />
      </div>
    </div>

    <!-- table -->
    <VueGoodTableRemote
      ref="tableData"
      :page="page"
      :columns="columns"
      :columns-top="columnsTop"
      :rows="items"
      :line-numbers="true"
      theme="black-rhino"
      :is-loading="$wait.is('reports-loading')"

      :pagination-enabled="true"
      :total-records="totalRecords"
      table-mode="remote"
      :sort-options="{ enabled: false }"
      @onLoadData="onLoadData"
    >
      <template slot="table-actions-bottom2">
        <tr class="tr-on-hover">
          <td
            colspan="2"
            class="text-center black--text text-total"
          >
            <span>{{ $t('Summary') }}</span>
          </td>
          <td
            class="text-right text-total"
            :class="[Number(summary.amount) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.amount) }}
          </td>
          <td
            class="text-right text-total"
            :class="[Number(summary.validAmount) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.validAmount) }}
          </td>
          <td
            class="text-right black--text text-total"
          >
            {{ summary.stackCount }}
          </td>

          <!-- member -->
          <td
            class="text-right text-total"
            :class="[Number(summary.winlose) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.winlose) }}
          </td>
          <!-- end member -->

        </tr>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <!-- <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div> -->

        <!-- Column: Action -->
        <span v-if="FieldNumberLists.some(list => list === props.column.field)">
          <span
            class="text-total"
            :class="[Number(props.formattedRow[props.column.field]) < 0 ? 'red--text' : 'black--text']"
          >{{ DecimalFormat(props.formattedRow[props.column.field]) }}</span>
        </span>
        <div
          v-else-if="props.column.field === 'stackCount'"
        >
          <span
            class="text-total black--text"
          >{{ DecimalFormatCustomFix(props.formattedRow[props.column.field], 0) }}</span>
        </div>
        <div
          v-else-if="props.column.field === '_id'"
        >
          <!-- <b-button
            class="link--text"
            variant="link"
            :to="onViewLists(props.row)"
          >
            {{ props.formattedRow[props.column.field] }}
          </b-button> -->

          <div class="d-flex flex-row justify-content-between">
            <div>
              <span
                class="link--text"
                @click="onViewListsClick(props.row)"
              >{{ props.formattedRow[props.column.field] }}</span>
            </div>

            <div v-if="IsShowViewTransactionPlayer(props.row.group)">
              <b-tooltip
                :target="`tooltip-target-report-get-outstanding-player-${props.formattedRow[props.column.field]}`"
                triggers="hover"
              >
                {{ $t('Get Outstanding Player') }}
              </b-tooltip>
              <b-icon
                :id="`tooltip-target-report-get-outstanding-player-${props.formattedRow[props.column.field]}`"
                icon="eye-fill"
                variant="primary"
                style="cursor: pointer"
                @click="onViewTransactionsPlayer(props.row)"
              />
            </div>
          </div>

        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="loadingContent">
        <div
          class="spinner-border text-primary"
          style="width: 3rem; height: 3rem;"
          role="status"
        >
          <span class="sr-only">{{ $t('Loading') }}...</span>
        </div>
      </template>
    </VueGoodTableRemote>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table-custom'
import { AgentTypes, IsReportUpline } from '@/utils/agent'
import { DecimalFormat, DecimalFormatCustomFix } from '@/utils/number'
import VueGoodTableRemote from '@/components/VueGoodTableRemote.vue'
import VueGoodTableSwitchCompactMode from '@/components/VueGoodTableSwitchCompactMode'

export default {
  components: {
    VueGoodTable,
    VueGoodTableSwitchCompactMode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCardCode,
    BButton,
    VueGoodTableRemote,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
    uplineType: {
      type: String,
      required: true,
    },
    groupType: {
      type: String,
      required: true,
    },
    isSimple: { type: Boolean, required: true },
    refKeyText: { type: String, required: true },
    page: { type: [Number, String], required: true },
    // groupId: { type: String, required: true },

  },
  data() {
    return {
      AgentTypes,
    }
  },
  computed: {
    bgTotalCom() {
      return 'table__tdclass__lists'
    },
    columnsTop() {
      const headers = [
        {
          colspan: 4,
        },
        {
          label: this.$t('Member'),
          colspan: 1,
          style: {
            'text-align': 'center',
          },
        },
      ]
      return headers
    },
    columns() {
      const headers = [
        {
          label: String(this.refKeyText)[0].toUpperCase() + String(this.refKeyText).slice(1),
          field: '_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ...',
            formatValue(value) {
              return String(value).trim()
            },
          },
          sortable: false,
          width: '120px',
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'number',
          width: '120px',
        },
        {
          label: 'ValidAmount',
          field: 'validAmount',
          type: 'number',
          width: '80px',
          sortable: false,
        },
        {
          label: 'StackCount',
          field: 'stackCount',
          type: 'number',
          tdClass: 'black--text',
          width: '120px',
        },
        {
          label: 'W/L',
          field: 'winlose',
          type: 'number',
          width: '120px',
        },
      ]

      return headers
    },
    FieldNumberLists() {
      return [
        "winlose",
        // "stackCount",
        "validAmount",
        "amount",
      ]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
  },
  methods: {
    IsReportUpline,
    DecimalFormat,
    DecimalFormatCustomFix,
    IsShowViewTransactionPlayer(rowGroup) {
      // const rowGroup = row.group
      const groupType = rowGroup?.type
      return groupType && !['API', 'COMPANY', "PARTNER"].includes(groupType) && groupType !== "MEMBER"
    },
    onViewTransactionsPlayer(row) {
      const rowGroup = row.group

      const groupType = rowGroup.type
      const groupId = rowGroup._id
      const groupUsername = rowGroup.username

      // const uplineGroupType = rowGroup.upline.type
      // const uplineGroupId = rowGroup.upline._id

      const isGroupMember = groupType === 'MEMBER'
      if (isGroupMember) {
        const routeData = this.$router.resolve({
          name: 'reports-player-outstanding-lists',
          params: {
            groupId: groupUsername,
          },
          query: {
            ...this.$route.query,
            searchUsername: '',
            isSimple: this.isSimple,
          },
        });
        window.open(routeData.href, '_blank');
        return
      }

      // api, company, partner, senior, master
      const routeData = this.$router.resolve({
        name: 'reports-players-outstanding',
        params: {
          ...!isGroupMember && { groupId },
        },
        query: {
          ...this.$route.query,
          searchUsername: '',
          isSimple: this.isSimple,
        },
      });
      window.open(routeData.href, '_blank');
    },
    onViewListsClick(row) {
      this.$router.push(this.onViewLists(row))
    },
    onViewLists(row) {
      // const rowGroup = row.group
      // const groupType = rowGroup.type
      // const groupId = rowGroup._id
      // const groupUsername = rowGroup.username

      // return {
      //   name: 'reports-player-outstanding-lists',
      //   params: {
      //     groupId: groupUsername,
      //   },
      //   query: {
      //     ...this.$route.query,
      //     searchUsername: '',
      //     isSimple: this.isSimple,
      //   },
      // }

      const rowGroup = row.group
      const groupType = rowGroup.type
      const groupId = rowGroup._id

      // const uplineGroupType = rowGroup.upline.type
      const uplineGroupId = rowGroup.upline._id

      const isOwner = groupId === uplineGroupId
      if (isOwner || groupType === 'AGENT' || groupType === 'MEMBER') {
        return this.onViewTransactionsPlayer(row)
      }

      // api, company, partner, senior, master
      return {
        name: this.$route.name,
        params: {
          groupId,
        },
        query: {
          ...this.$route.query,
          searchUsername: '',
          isSimple: this.isSimple,
        },
      }
    },
    onLoadData(serverParams) {
      this.$router.push({
        name: 'reports-player-outstanding',
        params: this.$route.params,
        query: {
          ...this.$route.query,
          ...serverParams,
        },
      })
      this.$emit('update:page', serverParams.page)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
// @import '@/assets/scss/table-list.scss';

// .tr-on-hover:hover {background-color:#664cfd2c!important;}
// .text-total {
//   // font-size: 11.5px!important;
//   font-weight: bold!important;

// }
// .red--text {
//   color: red!important;
//   // font-weight: bold;
// }
// .black--text {
//   color: black!important;
//   // font-weight: bold;
// }
// .link--text {
//   cursor: pointer;
//   color: #00f;
//   font-weight: bold;
// }

</style>
